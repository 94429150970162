<div class="tw-mb-4 tw-flex tw-items-start tw-justify-between">
  <div>
    <bit-breadcrumbs *ngIf="showBreadcrumbs">
      <bit-breadcrumb
        *ngIf="activeOrganizationId"
        [route]="[]"
        [queryParams]="{ organizationId: activeOrganizationId, collectionId: All }"
        queryParamsHandling="merge"
      >
        {{ activeOrganizationId | orgNameFromId : organizations }} {{ "vault" | i18n | lowercase }}
      </bit-breadcrumb>
      <ng-container>
        <bit-breadcrumb
          *ngFor="let collection of collections"
          icon="bwi-collection"
          [route]="[]"
          [queryParams]="{ collectionId: collection.id }"
          queryParamsHandling="merge"
        >
          {{ collection.name }}
        </bit-breadcrumb>
      </ng-container>
    </bit-breadcrumbs>
    <h1 class="tw-mb-0 tw-mt-1 tw-flex tw-items-center tw-space-x-2">
      <i
        *ngIf="filter.collectionId && filter.collectionId !== All"
        class="bwi bwi-collection"
        aria-hidden="true"
      ></i>
      <span>{{ title }}</span>
      <small *ngIf="loading">
        <i
          class="bwi bwi-spinner bwi-spin text-muted"
          title="{{ 'loading' | i18n }}"
          aria-hidden="true"
        ></i>
        <span class="tw-sr-only">{{ "loading" | i18n }}</span>
      </small>
    </h1>
  </div>

  <div *ngIf="filter.type !== 'trash'" class="tw-shrink-0">
    <button type="button" bitButton buttonType="primary" (click)="addCipher()">
      <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>
      {{ "newItem" | i18n }}
    </button>
  </div>
</div>
