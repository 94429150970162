<form [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog [disablePadding]="!loading" dialogSize="large">
    <span bitDialogTitle>
      <ng-container *ngIf="editMode">
        {{ "editCollection" | i18n }}
        <span class="tw-text-sm tw-normal-case tw-text-muted" *ngIf="!loading">{{
          collection.name
        }}</span>
      </ng-container>
      <ng-container *ngIf="!editMode">
        {{ "newCollection" | i18n }}
      </ng-container>
    </span>
    <div bitDialogContent>
      <ng-container *ngIf="loading" #spinner>
        <i class="bwi bwi-spinner bwi-lg bwi-spin" aria-hidden="true"></i>
      </ng-container>
      <bit-tab-group *ngIf="!loading" [(selectedIndex)]="tabIndex">
        <bit-tab label="{{ 'collectionInfo' | i18n }}">
          <bit-form-field>
            <bit-label>{{ "name" | i18n }}</bit-label>
            <input bitInput appAutofocus formControlName="name" />
          </bit-form-field>

          <bit-form-field>
            <bit-label>{{ "externalId" | i18n }}</bit-label>
            <input bitInput formControlName="externalId" />
            <bit-hint>{{ "externalIdDesc" | i18n }}</bit-hint>
          </bit-form-field>

          <bit-form-field>
            <bit-label>{{ "nestCollectionUnder" | i18n }}</bit-label>
            <bit-select bitInput formControlName="parent">
              <bit-option [value]="undefined" [label]="'noCollection' | i18n"> </bit-option>
              <bit-option
                *ngIf="deletedParentName"
                disabled
                icon="bwi-collection"
                [value]="deletedParentName"
                label="{{ deletedParentName }} ({{ 'deleted' | i18n }})"
              >
              </bit-option>
              <bit-option
                *ngFor="let collection of nestOptions"
                icon="bwi-collection"
                [value]="collection.name"
                [label]="collection.name"
              >
              </bit-option>
            </bit-select>
          </bit-form-field>
        </bit-tab>
        <bit-tab label="{{ 'access' | i18n }}">
          <bit-access-selector
            *ngIf="organization.useGroups"
            [permissionMode]="PermissionMode.Edit"
            formControlName="access"
            [items]="accessItems"
            [columnHeader]="'groupAndMemberColumnHeader' | i18n"
            [selectorLabelText]="'selectGroupsAndMembers' | i18n"
            [selectorHelpText]="'userPermissionOverrideHelper' | i18n"
            [emptySelectionText]="'noMembersOrGroupsAdded' | i18n"
          ></bit-access-selector>
          <bit-access-selector
            *ngIf="!organization.useGroups"
            [permissionMode]="PermissionMode.Edit"
            formControlName="access"
            [items]="accessItems"
            [columnHeader]="'memberColumnHeader' | i18n"
            [selectorLabelText]="'selectMembers' | i18n"
            [emptySelectionText]="'noMembersAdded' | i18n"
          ></bit-access-selector>
        </bit-tab>
      </bit-tab-group>
    </div>
    <ng-container bitDialogFooter>
      <button type="submit" bitButton bitFormButton buttonType="primary" [disabled]="loading">
        {{ "save" | i18n }}
      </button>
      <button
        type="button"
        bitButton
        bitFormButton
        buttonType="secondary"
        (click)="cancel()"
        [disabled]="loading"
      >
        {{ "cancel" | i18n }}
      </button>
      <button
        *ngIf="editMode && organization?.canDeleteAssignedCollections"
        type="button"
        bitIconButton="bwi-trash"
        buttonType="danger"
        class="tw-ml-auto"
        bitFormButton
        [appA11yTitle]="'delete' | i18n"
        [bitAction]="delete"
        [disabled]="loading"
      ></button>
    </ng-container>
  </bit-dialog>
</form>
