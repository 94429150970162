<!-- eslint-disable tailwindcss/no-custom-classname -->
<div *ngIf="accountCreateOnly" class="">
  <h1 class="tw-mt-12 tw-text-center tw-text-xl">{{ "createAccount" | i18n }}</h1>
  <div
    class="tw-min-w-xl tw-m-auto tw-max-w-xl tw-rounded tw-border tw-border-solid tw-border-secondary-300 tw-bg-background tw-p-8"
  >
    <app-register-form
      [queryParamEmail]="email"
      [enforcedPolicyOptions]="enforcedPolicyOptions"
      [referenceDataValue]="referenceData"
    ></app-register-form>
  </div>
</div>
<div *ngIf="!accountCreateOnly">
  <div class="tw-absolute tw--z-10 tw--mt-48 tw-h-[28rem] tw-w-full tw-bg-background-alt2"></div>
  <div class="tw-min-w-4xl tw-mx-auto tw-flex tw-max-w-screen-xl tw-gap-12 tw-px-4">
    <div class="tw-w-1/2">
      <img
        alt="Bitwarden"
        style="height: 50px; width: 335px"
        class="tw-mt-6"
        src="../../images/register-layout/logo-horizontal-white.svg"
      />

      <div class="tw-pt-12">
        <!-- Layout params are used by marketing to determine left-hand content -->
        <app-default-content *ngIf="layout === layouts.default"></app-default-content>
        <app-teams-content *ngIf="layout === layouts.teams"></app-teams-content>
        <app-teams1-content *ngIf="layout === layouts.teams1"></app-teams1-content>
        <app-teams2-content *ngIf="layout === layouts.teams2"></app-teams2-content>
        <app-enterprise-content *ngIf="layout === layouts.enterprise"></app-enterprise-content>
        <app-enterprise1-content *ngIf="layout === layouts.enterprise1"></app-enterprise1-content>
        <app-enterprise2-content *ngIf="layout === layouts.enterprise2"></app-enterprise2-content>
        <app-cnet-enterprise-content
          *ngIf="layout === layouts.cnetcmpgnent"
        ></app-cnet-enterprise-content>
        <app-cnet-individual-content
          *ngIf="layout === layouts.cnetcmpgnind"
        ></app-cnet-individual-content>
        <app-cnet-teams-content *ngIf="layout === layouts.cnetcmpgnteams"></app-cnet-teams-content>
        <app-abm-enterprise-content
          *ngIf="layout === layouts.abmenterprise"
        ></app-abm-enterprise-content>
        <app-abm-teams-content *ngIf="layout === layouts.abmteams"></app-abm-teams-content>
      </div>
    </div>
    <div class="tw-w-1/2">
      <div *ngIf="!useTrialStepper">
        <div
          class="tw-min-w-xl tw-m-auto tw-mt-28 tw-max-w-xl tw-rounded tw-border tw-border-solid tw-border-secondary-300 tw-bg-background tw-p-8"
        >
          <app-register-form
            [queryParamEmail]="email"
            [enforcedPolicyOptions]="enforcedPolicyOptions"
            [referenceDataValue]="referenceData"
          ></app-register-form>
        </div>
      </div>
      <div class="tw-pt-44" *ngIf="useTrialStepper">
        <div class="tw-rounded tw-border tw-border-solid tw-border-secondary-300 tw-bg-background">
          <div class="tw-flex tw-h-12 tw-w-full tw-items-center tw-rounded-t tw-bg-secondary-100">
            <h2 class="tw-mb-0 tw-pl-4 tw-text-base tw-font-bold tw-uppercase">
              Start your 7-Day free trial of Bitwarden for {{ org }}
            </h2>
          </div>
          <app-vertical-stepper #stepper linear (selectionChange)="stepSelectionChange($event)">
            <app-vertical-step label="Create Account" [editable]="false" [subLabel]="email">
              <app-register-form
                [isInTrialFlow]="true"
                (createdAccount)="createdAccount($event)"
                [referenceDataValue]="referenceData"
              ></app-register-form>
            </app-vertical-step>
            <app-vertical-step label="Organization Information" [subLabel]="orgInfoSubLabel">
              <app-org-info [nameOnly]="true" [formGroup]="orgInfoFormGroup"></app-org-info>
              <button
                type="button"
                bitButton
                buttonType="primary"
                [disabled]="orgInfoFormGroup.get('name').invalid"
                cdkStepperNext
              >
                Next
              </button>
            </app-vertical-step>
            <app-vertical-step label="Billing" [subLabel]="billingSubLabel">
              <app-billing
                *ngIf="stepper.selectedIndex === 2"
                [plan]="plan"
                [product]="product"
                [orgInfoForm]="orgInfoFormGroup"
                (previousStep)="previousStep()"
                (onTrialBillingSuccess)="billingSuccess($event)"
              ></app-billing>
            </app-vertical-step>
            <app-vertical-step label="Confirmation Details" [applyBorder]="false">
              <app-trial-confirmation-details
                [email]="email"
                [orgLabel]="orgLabel"
              ></app-trial-confirmation-details>
              <div class="tw-mb-3 tw-flex">
                <button type="button" bitButton buttonType="primary" (click)="navigateToOrgVault()">
                  Get Started
                </button>
                <button
                  type="button"
                  bitButton
                  buttonType="secondary"
                  (click)="navigateToOrgInvite()"
                  class="tw-ml-3 tw-inline-flex tw-items-center tw-px-3"
                >
                  Invite Users
                </button>
              </div>
            </app-vertical-step>
          </app-vertical-stepper>
        </div>
      </div>
    </div>
  </div>
</div>
